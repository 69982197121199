import { useMemo } from "react";
import useCommerceCart from "@vercel/commerce/cart/use-cart";
import { getCartQuery, normalizeCart } from "../utils";
export default useCommerceCart;
export const handler = {
    fetchOptions: {
        query: getCartQuery
    },
    async fetcher ({ input: { cartId  } , options , fetch  }) {
        if (cartId) {
            const { cart  } = await fetch({
                ...options,
                variables: {
                    cartId
                }
            });
            return cart ? normalizeCart(cart) : null;
        }
        return null;
    },
    useHook: ({ useData  })=>{
        return (input)=>{
            const response = useData({
                swrOptions: {
                    revalidateOnFocus: false,
                    ...input == null ? void 0 : input.swrOptions
                }
            });
            return useMemo(()=>{
                return Object.create(response, {
                    isEmpty: {
                        get () {
                            var ref;
                            return (((ref = response.data) == null ? void 0 : ref.lineItems.length) ?? 0) <= 0;
                        },
                        enumerable: true
                    }
                });
            }, [
                response
            ]);
        };
    }
};
