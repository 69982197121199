import { colorMap } from "./colors";
const money = ({ amount , currencyCode  })=>{
    return {
        value: +amount,
        currencyCode
    };
};
const normalizeProductOption = ({ id , name: displayName , values  })=>{
    return {
        __typename: "MultipleChoiceOption",
        id,
        displayName: displayName.toLowerCase(),
        values: values.map((value)=>{
            let output = {
                label: value
            };
            if (displayName.match(/colou?r/gi)) {
                const mappedColor = colorMap[value.toLowerCase().replace(/ /g, "")];
                if (mappedColor) {
                    output = {
                        ...output,
                        hexColors: [
                            mappedColor
                        ]
                    };
                }
            }
            return output;
        })
    };
};
const normalizeProductImages = ({ edges  })=>{
    return edges == null ? void 0 : edges.map(({ node: { url , originalSrc , ...rest }  })=>({
            url: url || originalSrc || "/",
            ...rest
        }));
};
const normalizeProductVariants = ({ edges  })=>{
    return edges == null ? void 0 : edges.map(({ node: { id , selectedOptions , sku , title , priceV2 , compareAtPriceV2 , requiresShipping , availableForSale , quantityAvailable ,  } ,  })=>{
        return {
            id,
            name: title,
            sku: sku ?? id,
            price: +priceV2.amount,
            listPrice: +(compareAtPriceV2 == null ? void 0 : compareAtPriceV2.amount),
            requiresShipping,
            availableForSale,
            quantityAvailable,
            options: selectedOptions.map(({ name , value  })=>{
                const options = normalizeProductOption({
                    id,
                    name,
                    values: [
                        value
                    ]
                });
                return options;
            })
        };
    });
};
export function normalizeMetafields(metafields) {
    const fields = [];
    metafields.forEach((m)=>{
        fields.push({
            key: m.node.key,
            value: m.node.value
        });
    });
    return fields;
}
export function normalizeProduct({ id , title: name , vendor , images , variants , description , descriptionHtml , handle , priceRange , options , metafields , ...rest }) {
    return {
        id,
        name,
        vendor,
        path: `/${handle}`,
        slug: handle == null ? void 0 : handle.replace(/^\/+|\/+$/g, ""),
        price: money(priceRange == null ? void 0 : priceRange.minVariantPrice),
        images: normalizeProductImages(images),
        variants: variants ? normalizeProductVariants(variants) : [],
        options: options ? options.filter((o)=>o.name !== "Title") // By default Shopify adds a 'Title' name when there's only one option. We don't need it. https://community.shopify.com/c/Shopify-APIs-SDKs/Adding-new-product-variant-is-automatically-adding-quot-Default/td-p/358095
        .map((o)=>normalizeProductOption(o)) : [],
        ...description && {
            description
        },
        ...descriptionHtml && {
            descriptionHtml
        },
        metafields: metafields ? normalizeMetafields(metafields) : [],
        origin: {
            id,
            title: name,
            vendor,
            images,
            variants,
            description,
            descriptionHtml,
            handle,
            priceRange,
            options,
            metafields,
            ...rest
        },
        ...rest
    };
}
export function normalizeCollection({ id , title: name , description , descriptionHtml , handle , metafields , ...rest }) {
    return {
        id,
        name,
        path: `/${handle}`,
        slug: handle == null ? void 0 : handle.replace(/^\/+|\/+$/g, ""),
        ...description && {
            description
        },
        ...descriptionHtml && {
            descriptionHtml
        },
        metafields: metafields ? normalizeMetafields(metafields) : [],
        origin: {
            id,
            title: name,
            description,
            descriptionHtml,
            handle,
            metafields,
            ...rest
        },
        ...rest
    };
}
export function normalizeCart(cart) {
    var ref, ref1, ref2, ref3, ref4;
    return {
        id: cart.id,
        url: cart.checkoutUrl,
        customerId: "",
        email: "",
        createdAt: cart.createdAt,
        currency: {
            code: (ref = cart.cost.totalAmount) == null ? void 0 : ref.currencyCode
        },
        taxesIncluded: false,
        lineItems: (ref1 = cart.lines) == null ? void 0 : ref1.edges.map(normalizeLineItem),
        lineItemsSubtotalPrice: +((ref2 = cart.cost.subtotalAmount) == null ? void 0 : ref2.amount),
        subtotalPrice: +((ref3 = cart.cost.subtotalAmount) == null ? void 0 : ref3.amount),
        totalPrice: (ref4 = cart.cost.totalAmount) == null ? void 0 : ref4.amount,
        discounts: [],
        attributes: cart.attributes,
        note: (cart == null ? void 0 : cart.note) ?? ""
    };
}
function normalizeLineItem({ node: { id , merchandise , quantity , discountAllocations  }  }) {
    var ref, ref5, ref6, ref7;
    return {
        id,
        variantId: String(merchandise == null ? void 0 : merchandise.id),
        productId: String(merchandise == null ? void 0 : merchandise.product.id),
        name: `${merchandise.product.title}`,
        quantity,
        variant: {
            id: String(merchandise == null ? void 0 : merchandise.id),
            sku: (merchandise == null ? void 0 : merchandise.sku) ?? "",
            barcode: (merchandise == null ? void 0 : merchandise.barcode) ?? "",
            name: merchandise == null ? void 0 : merchandise.title,
            quantityAvailable: Number(merchandise == null ? void 0 : merchandise.quantityAvailable),
            image: {
                url: (merchandise == null ? void 0 : (ref = merchandise.image) == null ? void 0 : ref.url) || "/product-img-placeholder.svg"
            },
            requiresShipping: (merchandise == null ? void 0 : merchandise.requiresShipping) ?? false,
            price: merchandise == null ? void 0 : merchandise.price.amount,
            listPrice: merchandise == null ? void 0 : (ref5 = merchandise.compareAtPrice) == null ? void 0 : ref5.amount,
            productTags: (merchandise == null ? void 0 : (ref6 = merchandise.product) == null ? void 0 : ref6.tags) ?? []
        },
        path: String(merchandise == null ? void 0 : (ref7 = merchandise.product) == null ? void 0 : ref7.handle),
        discounts: [],
        discountAllocations,
        options: (merchandise == null ? void 0 : merchandise.title) == "Default Title" ? [] : merchandise == null ? void 0 : merchandise.selectedOptions
    };
}
export const normalizePage = ({ title: name , handle , ...page }, locale = "en-AU")=>({
        ...page,
        url: `${handle}`,
        name
    });
export const normalizePages = (edges, locale)=>{
    return edges == null ? void 0 : edges.map((edge)=>normalizePage(edge.node, locale));
};
export const normalizeCategory = ({ name , handle , id  })=>({
        id,
        name,
        slug: handle,
        path: `/${handle}`
    });
export const normalizeAlgoliaHit = ({ objectID , title , vendor , handle , body_html_safe , price , compare_at_price , tags , image , named_tags , product_type , ...rest }, currencyCode)=>{
    return {
        name: title,
        vendor,
        slug: handle,
        type: product_type,
        description: body_html_safe,
        path: `/product/${handle}`,
        variants: [],
        images: image ? [
            {
                url: image
            }, 
        ] : [],
        price: {
            value: price,
            currencyCode
        },
        tags,
        metafields: undefined,
        ...rest,
        id: objectID
    };
};
export function normalizeEdges(edges) {
    return edges.flatMap((edge)=>edge.node);
}
