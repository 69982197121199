import { useCallback } from "react";
import { cartAttributesUpdateMutation, cartNoteUpdateMutation, getCartId, normalizeCart } from "../utils";
import useUpdateCart from "@vercel/commerce/cart/use-update-cart";
import useCart from "./use-cart";
export default useUpdateCart;
export const handler = {
    fetchOptions: {
        query: cartAttributesUpdateMutation
    },
    async fetcher ({ input , options , fetch  }) {
        let cartId = getCartId();
        const { attributes , note  } = input;
        if (cartId) {
            await fetch({
                ...options,
                query: cartNoteUpdateMutation,
                variables: {
                    cartId,
                    note
                }
            });
            if (attributes) {
                const { cartAttributesUpdate: { cart  } ,  } = await fetch({
                    ...options,
                    variables: {
                        cartId,
                        input: attributes
                    }
                });
                return cart ? normalizeCart(cart) : null;
            }
        }
        return null;
    },
    useHook: ({ fetch  })=>()=>{
            const { mutate  } = useCart();
            return useCallback(async function updateCart(input) {
                const data = await fetch({
                    input
                });
                await mutate(data, false);
                return data;
            }, [
                fetch,
                mutate
            ]);
        }
};
