import { useCallback } from "react";
import { ValidationError } from "@vercel/commerce/utils/errors";
import useRemoveItem from "@vercel/commerce/cart/use-remove-item";
import useCart from "./use-cart";
export default useRemoveItem;
import { cartLinesRemoveMutation, getCartId, normalizeCart } from "../utils";
export const handler = {
    fetchOptions: {
        query: cartLinesRemoveMutation
    },
    async fetcher ({ input: { itemId  } , options , fetch  }) {
        var ref;
        const data = await fetch({
            ...options,
            variables: {
                cartId: getCartId(),
                lineIds: [
                    itemId
                ]
            }
        });
        const updatedCart = (ref = data.cartLinesRemove) == null ? void 0 : ref.cart;
        return updatedCart ? normalizeCart(updatedCart) : undefined;
    },
    useHook: ({ fetch  })=>{
        return (ctx = {})=>{
            const { item  } = ctx;
            const { mutate  } = useCart();
            const removeItem = async (input)=>{
                const itemId = (input == null ? void 0 : input.id) ?? (item == null ? void 0 : item.id);
                if (!itemId) {
                    throw new ValidationError({
                        message: "Invalid input used for this operation"
                    });
                }
                const data = await fetch({
                    input: {
                        itemId
                    }
                });
                await mutate(data, false);
                return data;
            };
            return useCallback(removeItem, [
                fetch,
                mutate
            ]);
        };
    }
};
