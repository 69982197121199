import Cookies from "js-cookie";
import { SHOPIFY_CART_ID_COOKIE, SHOPIFY_CART_URL_COOKIE, SHOPIFY_COOKIE_EXPIRE } from "../const";
import { cartCreateMutation } from "./mutations";
export const cartCreate = async (fetch, lineItems)=>{
    const { cartCreate: cartCreate1  } = await fetch({
        query: cartCreateMutation,
        variables: {
            input: {
                lines: lineItems
            }
        }
    });
    const cart = cartCreate1 == null ? void 0 : cartCreate1.cart;
    if (cart) {
        const cartId = cart == null ? void 0 : cart.id;
        const options = {
            expires: SHOPIFY_COOKIE_EXPIRE
        };
        Cookies.set(SHOPIFY_CART_ID_COOKIE, cartId, options);
        if (cart == null ? void 0 : cart.checkoutUrl) {
            Cookies.set(SHOPIFY_CART_URL_COOKIE, cart.checkoutUrl.replace(process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN, process.env.NEXT_PUBLIC_SHOPIFY_STORE_CUSTOM_DOMAIN), options);
        }
    }
    return cart;
};
export default cartCreate;
