import useAddItem from "@vercel/commerce/cart/use-add-item";
import { CommerceError } from "@vercel/commerce/utils/errors";
import Cookies from "js-cookie";
import { useCallback } from "react";
import useCart from "./use-cart";
import { SHOPIFY_CART_ID_COOKIE, SHOPIFY_CART_URL_COOKIE } from "../const";
import { cartCreate, cartLinesAddMutation, getCartId, normalizeCart } from "../utils";
export default useAddItem;
export const handler = {
    fetchOptions: {
        query: cartLinesAddMutation
    },
    async fetcher ({ input: itemOrItems , options , fetch  }) {
        let lineItems = [];
        if (Array.isArray(itemOrItems)) {
            lineItems = itemOrItems.map((item)=>{
                if (item.quantity && (!Number.isInteger(item.quantity) || item.quantity < 1)) {
                    throw new CommerceError({
                        message: "The item quantity has to be a valid integer greater than 0"
                    });
                }
                return {
                    merchandiseId: item.variantId,
                    quantity: item.quantity ?? 1
                };
            });
        } else {
            const item = itemOrItems;
            if (item.quantity && (!Number.isInteger(item.quantity) || item.quantity < 1)) {
                throw new CommerceError({
                    message: "The item quantity has to be a valid integer greater than 0"
                });
            }
            lineItems = [
                {
                    merchandiseId: item.variantId,
                    quantity: item.quantity ?? 1
                }, 
            ];
        }
        let cartId = getCartId();
        if (!cartId) {
            const cart = await cartCreate(fetch, lineItems);
            return normalizeCart(cart);
        } else {
            try {
                const { cartLinesAdd  } = await fetch({
                    ...options,
                    variables: {
                        cartId,
                        lines: lineItems
                    }
                });
                const cart = cartLinesAdd == null ? void 0 : cartLinesAdd.cart;
                return cart ? normalizeCart(cart) : undefined;
            } catch (error) {
                if (error instanceof Error) {
                    console.log("Checkout error", error, typeof error, error == null ? void 0 : error.message);
                    if ((error == null ? void 0 : error.message) === "Checkout does not exist" || (error == null ? void 0 : error.message) === "Unable to access Checkout.") {
                        console.log("Will remove checkout cookie...");
                        Cookies.remove(SHOPIFY_CART_ID_COOKIE);
                        Cookies.remove(SHOPIFY_CART_URL_COOKIE);
                    }
                }
            }
        }
    },
    useHook: ({ fetch  })=>()=>{
            const { mutate  } = useCart();
            return useCallback(async function addItem(input) {
                const data = await fetch({
                    input
                });
                await mutate(data, false);
                return data;
            }, [
                fetch,
                mutate
            ]);
        }
};
