import { LineItem } from '@commerce/types/cart'
import SidebarLayout from '@components/common/SidebarLayout'
import { Check, LegacyCross } from '@components/icons'
import AfterPay from '@components/icons/AfterPay'
import Amex from '@components/icons/Amex'
import MasterCard from '@components/icons/MasterCard'
import PayPal from '@components/icons/PayPal'
import Visa from '@components/icons/Visa'
import Zip from '@components/icons/Zip'
import Notice from '@components/common/Notice'
import { Button } from '@components/ui'
import { useUI } from '@components/ui/context'
import { useUpdateCart } from '@framework/cart'
import useCart from '@framework/cart/use-cart'
import useRemoveItem from '@framework/cart/use-remove-item'
import usePrice from '@framework/product/use-price'
import { useTagPromoOff } from '@lib/TagPromoOffProvider'
import shopifyBuyClient from '@lib/shopify-buy-client'
import splitLineItemsByDeliveryType from '@lib/split-lineitems-by-delivery-type'
import sortedByDistanceMatrix from '@lib/store-finder-page/helpers/sortedByDistanceMatrix'
import { StoreLocation } from '@lib/store-finder-page/types'
import { useStoreSidebar } from '@lib/store-finder-sidebar'
import cn from 'clsx'
import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
import AddGiftWrappingControl from '../AddGiftWrappingControl/AddGiftWrappingControl'

declare global {
  interface Window {
    zE?: any
  }
}

type PromoListType = {
  collectionTag: string
  productType: 'percent' | 'free' | 'priceoff'
  pecentageOff?: number
  priceOff?: number
  minSpend?: {
    currentSpend: number
    minSpendValue: number
  }
  promoHandle: string
  promoRemovePrice: number
  lineItem: any
}

type minSpendType = {
  tag: any
  productsCart: { name: string; price: number; quantity: number }[] // Assuming price should be a number
  spendNeeded: any
  currentSpend: number
}

type SpendStatusItem = {
  tag: string
  status: boolean
}

import { useAddItem } from '@framework/cart'
import itemFoundInCart from '@lib/itemFoundInCart'
import { promoFinder } from '@lib/promotion/promoFinder'
import getItemsWithHiddenTag from '@lib/search-hidden-products'
import CartItem from '../CartItem'
import s from './CartSidebarView.module.css'

const CartSidebarView = ({
  onClose,
  giftWrapping,
  giftWithPurchaseConfig,
}: {
  onClose: () => void
  giftWrapping: any
  giftWithPurchase: any
  giftWithPurchaseConfig: any
}) => {
  const { setSidebarView } = useUI()
  const { data, isLoading, isEmpty } = useCart()
  const [giftWrappingNote, setGiftWrappingNote] = useState<string | undefined>(
    ''
  )
  const [promoList, setPromoList] = useState<PromoListType[]>([])
  const removeItem = useRemoveItem()
  const giftNotePrefix = 'Gift-Wrapping-Note: '
  const addItem = useAddItem()
  const [removedFreeItem, setRemovedFreeitem] = useState<string[]>([])

  const handleRemoveFreeItem = (removeFreeItem: string) => {
    if (!removedFreeItem.some((item) => item === removeFreeItem)) {
      setRemovedFreeitem([...removedFreeItem, removeFreeItem])
    }
  }

  useEffect(() => {
    if (data) {
      shopifyBuyClient.checkout.fetch(data.id).then((checkout) => {
        const giftNote = checkout.note

        setGiftWrappingNote(giftNote?.replace(giftNotePrefix, ''))
      })
    }
  }, [])

  const isGiftWrappingAdded = data?.lineItems.find(
    (item) => item.variant.barcode === 'giftWrapping'
  )
    ? true
    : false

  const { myStore, stores } = useStoreSidebar()

  const updateCart = useUpdateCart()

  const handleClose = () => onClose()
  const goToCheckout = () => setSidebarView('CHECKOUT_VIEW')

  const error = null
  const success = null

  const { withTagPromoOff } = useTagPromoOff()

  const updatedLineItems = useMemo(
    () =>
      data?.lineItems?.map((item) => {
        const {
          price,
          compare_at_price: listPrice,
          isPromoOff,
        } = withTagPromoOff(
          item.variant.productTags ? item.variant.productTags : [],
          Number(item.variant.price),
          Number(item.variant.listPrice)
        )

        return {
          ...item,
          variant: {
            ...item.variant,
            price,
            listPrice,
            isPromoOff,
          },
        }
      }),
    [data]
  )

  const isCNCOnly = useMemo(
    () =>
      !!data?.lineItems?.find((item) =>
        item?.variant?.productTags?.includes('Weight:cnc')
      ),
    [data]
  )

  const total = useMemo(
    () =>
      (updatedLineItems as unknown as LineItem[])?.reduce(
        (sum, item) => sum + Number(item.variant.price) * item.quantity,
        0
      ),
    [updatedLineItems]
  )

  const [totalSpend, setTotalSpend] = useState(0)

  const uniqueDiscountedProducts = new Set()

  const totalAmountToRemove = promoList.reduce((total, promo) => {
    if (!uniqueDiscountedProducts.has(promo.promoHandle)) {
      uniqueDiscountedProducts.add(promo.promoHandle)
      return total + promo.promoRemovePrice
    }
    return total
  }, 0)

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(total) - totalAmountToRemove,
      currencyCode: data.currency.code,
    }
  )

  const { price: cartSubTotal } = usePrice(
    data && {
      amount: data.subtotalPrice - totalAmountToRemove,
      currencyCode: data.currency.code,
    }
  )

  const spiltLineItems = useMemo(
    () =>
      splitLineItemsByDeliveryType(updatedLineItems as unknown as LineItem[]),
    [updatedLineItems]
  )

  const giftWrappingProduct = spiltLineItems.vendor.find(
    (item) => item.path === 'gift-wrapping'
  )

  const updateGiftWrappingAttribute = async (note: String | undefined) => {
    if (data) {
      const giftWrappingLineItem = data.lineItems.find(
        (item) => item.path === 'gift-wrapping'
      )
      const lineItemsToUpdate = [
        {
          id: giftWrappingLineItem?.id,
          customAttributes: [
            {
              key: 'Note',
              value: note,
            },
          ],
        },
      ]

      shopifyBuyClient.checkout
        //@ts-ignore
        .updateLineItems(data.id, lineItemsToUpdate)
    }
  }
  const discountedProducts: {
    productId: any
    variantId: any
    quantity: number
  }[] = []

  useEffect(() => {
    const promoCheck = async () => {
      const promos = promoFinder(data?.lineItems, giftWithPurchaseConfig)
      if (promos) {
        promos.map(async (promo) => {
          let found
          const removeCheck = removedFreeItem.find(
            (item) => item === promo.promoHandle
          )

          const isPromoInList = promoList.some(
            (prevPromo) => prevPromo.collectionTag === promo.collectionTag
          )

          if (!isPromoInList && !removeCheck) {
            if (data?.lineItems) {
              found = itemFoundInCart(data?.lineItems, promo.promoHandle).item
            }

            if (
              !found &&
              !removeCheck &&
              !data?.lineItems.find(
                (item) => item === promo.lineItem && !isPromoInList
              )
            ) {
              discountedProducts.push({
                productId: promo.lineItem.product.id,
                variantId: promo.lineItem.product.variants[0].id,
                quantity: 1,
              })

              if (!isPromoInList) {
                setPromoList((prevPromoList) => [...prevPromoList, promo])
              }
            }
            if (found && !removeCheck) {
              if (!isPromoInList) {
                setPromoList((prevPromoList) => [...prevPromoList, promo])
              }
            }
          }

          if (removeCheck) {
            const index = promoList.findIndex(
              (item) => item.promoHandle === removeCheck
            )

            if (index !== -1) {
              const updatedPromoList = [
                ...promoList.slice(0, index),
                ...promoList.slice(index + 1),
              ]
              setPromoList(updatedPromoList)
            }
          }
        })
      }

      const promoListUpdate = promoList.filter((item1) =>
        promos.some((item2) => item1.collectionTag === item2.collectionTag)
      )

      if (promoListUpdate.length != promoList.length && promoList.length > 0) {
        setPromoList(promos)
      }

      if (data?.lineItems) {
        const hiddenArray = getItemsWithHiddenTag(data.lineItems)

        const checkAndRemoveItems = async () => {
          data.lineItems.forEach(async (lineItem) => {
            const matchFound = hiddenArray.find(
              (hiddenItem) => hiddenItem.id === lineItem.id
            )

            if (
              matchFound &&
              promos.filter((match) => match.lineItem).length === 0
            ) {
              await removeItem(matchFound)
            }
          })
        }
        await checkAndRemoveItems()

        if (discountedProducts.length > 0) {
          await addItem(
            // @ts-ignore
            discountedProducts.map((product) => {
              return {
                variantId: product.variantId,
                quantity: product.quantity,
              }
            })
          )
        }
      }
    }
    promoCheck()
  }, [promoList, data?.lineItems])

  const openZendeskChat = () => {
    if (typeof window !== 'undefined' && window.zE) {
      window.zE('webWidget', 'open')
    }
  }

  const displayCCNotice = () => {
    if (!Array.isArray(data?.lineItems)) return null
    const displayNotice = data?.lineItems.some((item) => {
      return (
        Array.isArray(item?.variant?.productTags) &&
        item.variant.productTags.includes('Weight:cnc')
      )
    })

    if (displayNotice)
      return (
        <div className={'pb-4'}>
          <Notice
            icon="fulfilmentInfo"
            content={
              <p>
                Some items in your cart are too large or fragile to be shipped.
                Please select 'Pickup in store' in checkout or{' '}
                <span
                  className="underline cursor-pointer"
                  onClick={openZendeskChat}
                >
                  contact us
                </span>{' '}
                for a shipping quote.
              </p>
            }
          />
        </div>
      )
    return null
  }

  return (
    <SidebarLayout
      className={cn({
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
    >
      {isLoading || isEmpty ? (
        <div className="flex-1 px-4 flex flex-col justify-center items-center">
          <h2 className="pt-6 text-2xl font-bold tracking-wide text-center">
            Your cart is empty
          </h2>
          <p className="text-accent-3 px-10 text-center pt-2">
            <Link href="/collections/new-arrivals">Shop new arrivals</Link>
          </p>
        </div>
      ) : error ? (
        <div className="flex-1 px-4 flex flex-col justify-center items-center">
          <span className="border border-white rounded-full flex items-center justify-center w-16 h-16">
            <LegacyCross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-xl font-light text-center">
            We couldn’t process the purchase. Please check your card information
            and try again.
          </h2>
        </div>
      ) : success ? (
        <div className="flex-1 px-4 flex flex-col justify-center items-center">
          <span className="border border-white rounded-full flex items-center justify-center w-16 h-16">
            <Check />
          </span>
          <h2 className="pt-6 text-xl font-light text-center">
            Thank you for your order.
          </h2>
        </div>
      ) : (
        <>
          <div className="px-4 sm:px-6 flex-1 relative">
            {!!spiltLineItems.vendor.length && (
              <div className="pt-[32px] ">
                {!!(
                  spiltLineItems.partner.length && spiltLineItems.vendor.length
                ) && <h3 className="my-5 font-bold">Delivered by Hoskings</h3>}
                <ul className={s.lineItemsList}>
                  {spiltLineItems.vendor
                    .filter((item) => item.path !== 'gift-wrapping')
                    .map((item: any) => (
                      <CartItem
                        key={item.id}
                        item={item}
                        currencyCode={data!.currency.code}
                        setGiftWrappingNote={setGiftWrappingNote}
                        giftWrappingNote={giftWrappingNote}
                        promoRemovePrice={promoList}
                        freeProductRemovedCallback={handleRemoveFreeItem}
                      />
                    ))}
                </ul>
              </div>
            )}
            {!!spiltLineItems.partner.length && (
              <div className="pt-[32px]">
                <h3 className="my-5 font-bold">Delivered by our Partner</h3>
                <ul className={s.lineItemsList}>
                  {spiltLineItems.partner.map((item: any) => (
                    <CartItem
                      key={item.id}
                      item={item}
                      currencyCode={data!.currency.code}
                      setGiftWrappingNote={setGiftWrappingNote}
                      giftWrappingNote={giftWrappingNote}
                      promoRemovePrice={promoList}
                      freeProductRemovedCallback={handleRemoveFreeItem}
                    />
                  ))}
                </ul>
              </div>
            )}

            {/* put gift wrapping product at the bottom of cart items */}
            {giftWrappingProduct && (
              <ul>
                <li className="test border-t border-gray-300 py-[24px]">
                  <CartItem
                    key={giftWrappingProduct.id}
                    item={giftWrappingProduct}
                    currencyCode={data!.currency.code}
                    setGiftWrappingNote={setGiftWrappingNote}
                    giftWrappingNote={giftWrappingNote}
                  />
                </li>
              </ul>
            )}
          </div>

          {giftWrapping?.status === true && !isGiftWrappingAdded && (
            <AddGiftWrappingControl giftWrapping={giftWrapping} />
          )}
          <div className="flex-shrink-0 px-[24px] py-[24px] sm:px-[24px] sticky z-20 bottom-0 w-full right-0 left-0 bg-accent-0 border-t text-[13px] leading-[24px]">
            {displayCCNotice()}
            <ul>
              <li className="flex justify-between py-0">
                <span>Subtotal</span>
                {/* <span className="font-bold">{subTotal}</span> */}
                <span className="font-bold">{cartSubTotal}</span>
              </li>
              {/* <li className="flex justify-between py-1">
                <span>Taxes</span>
                <span>Calculated at checkout</span>
              </li> */}
              <li className="flex justify-between pt-2 pb-[16px]">
                <span>Shipping</span>
                <span className="font-thin">Calculated at checkout</span>
              </li>
            </ul>
            {/* <div className="flex justify-between border-t border-accent-2 py-3 font-bold mb-2">
              <span>Total</span>
              <span>{total}</span>
            </div> */}
            <div className="bg-gray-300">
              {process.env.COMMERCE_CUSTOMCHECKOUT_ENABLED ? (
                <Button Component="a" width="100%" onClick={goToCheckout}>
                  {/* Checkout ({subTotal}) */}
                  Checkout ({cartSubTotal})
                </Button>
              ) : (
                <Button
                  variant="slim"
                  Component="a"
                  width="100%"
                  style={{
                    backgroundColor: 'rgba(70,69,68, 1)',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    lineHeight: '16px',
                    fontSize: '13px',
                    height: '48px',
                  }}
                  onClick={async () => {
                    let attributes: any[] = [
                      { key: 'cnc_only', value: isCNCOnly.toString() },
                    ]
                    let note = ''
                    if (myStore && stores) {
                      const { lat, lng } = myStore
                      const sortedStores = await sortedByDistanceMatrix(
                        stores as StoreLocation[],
                        {
                          lat,
                          lng,
                        }
                      )
                      attributes = [
                        ...attributes,
                        { key: 'my_store', value: myStore.title },
                        {
                          key: 'near_stores',
                          value: JSON.stringify(
                            sortedStores
                              ?.slice(0, 3)
                              .map((s) => (s as StoreLocation).title)
                          ),
                        },
                      ]
                    }
                    if (isGiftWrappingAdded) {
                      if (giftWrappingNote?.trim() !== '') {
                        await updateGiftWrappingAttribute(giftWrappingNote)
                        note = `${giftNotePrefix}${giftWrappingNote}`
                      } else {
                        await updateGiftWrappingAttribute('')
                      }
                    }

                    await updateCart({ attributes, note })
                    window.location.href = '/checkout'
                  }}
                >
                  Checkout
                </Button>)}
            </div>

            <div className={s.paymentIcons}>
              <PayPal />
              <MasterCard />
              <Visa />
              <AfterPay />
              <Zip />
              <Amex />
            </div>
          </div>
        </>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView
